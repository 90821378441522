import { DndContext, DragOverlay } from '@dnd-kit/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../api/functions';
import { moveTaskForSupervisors, tasksForSupervisorsState } from '../../../../redux/slices/hospitalitySlice';
import { Card } from '../../card/Card';
import { SupervisorsSection } from './supervisorsSection/SupervisorsSection';
import { TasksSection } from './tasksSection/TasksSection';

export const TasksToSupervisorsSection = () => {
    const dispatch = useDispatch();

    const tasks = useSelector(tasksForSupervisorsState);

    const [activeTask, setActiveTask] = useState(null);

    useEffect(() => {
        const interval = setInterval(
            () => {
                if (tasks.findIndex(task => task.isTouched) > -1) {
                    toast.error('Ваше распределение не применено!', toastOptions);
                }
            },
            3 * 60 * 1000
        );

        return () => clearInterval(interval);
    }, [tasks]);

    const handleDragStart = event => {
        setActiveTask(tasks.find(task => task.id === event.active.id));
    };

    const handleDragEnd = async event => {
        setActiveTask(null);

        if (event.over) {
            const userId = event.over.id === 'default' ? '' : event.over.id;
            const task = tasks.find(task => task.id === event.active.id && task.userId === userId);

            if (!task) {
                const result = await dispatch(
                    moveTaskForSupervisors({
                        data: {
                            id: event.active.id,
                            userId,
                        },
                    })
                );

                if (result.error) {
                    toast.error(result.message, toastOptions);
                }
            }
        }
    };

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className="flex h-full w-full flex-row overflow-hidden border-l border-l-gray-10">
                <TasksSection />
                <SupervisorsSection />
            </div>

            <DragOverlay dropAnimation={null}>
                {activeTask ? (
                    <Card typeId={activeTask.clearTypeId} statusId={activeTask.statusId}>
                        {activeTask.title}
                    </Card>
                ) : null}
            </DragOverlay>
        </DndContext>
    );
};
