import { useEffect } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getVenueStatusColorByServerStatus } from '../../../api/functions/bms/getVenueStatusByServerStatus';
import { useCheckRole } from '../../../hooks/useCheckRole';
import { bmsAlarmsState, bms_servers_list as bms_servers_list_state, bms_servers_state, getBMSServersList } from '../../../redux/slices/bms/bmsSliceServers';
import { clear_system_list } from '../../../redux/slices/bms/bmsSliceSystems';
import {
    bms_venues_list as bms_venues_list_state,
    getBMSUserVenuesList,
    venues_fetching as venues_fetching_state,
} from '../../../redux/slices/bms/bmsSliceVenues';
import { Loader } from '../../common/Loader';

export const VenueList = ({ setVenueId, setIsOpen }) => {
    const dispatch = useDispatch();

    const bms_venues_list = useSelector(bms_venues_list_state);
    const venues_fetching = useSelector(venues_fetching_state);
    const bms_servers_list = useSelector(bms_servers_list_state);
    const bmsServers = useSelector(bms_servers_state);
    const bmsAlarms = useSelector(bmsAlarmsState);

    const isObjectEdit = useCheckRole('bms', 'bms.object.view');

    useEffect(() => {
        dispatch(clear_system_list());

        if (!bms_venues_list.length) {
            dispatch(getBMSUserVenuesList());
        }
    }, [bms_venues_list]);

    useEffect(() => {
        if (!bms_servers_list.length) {
            dispatch(getBMSServersList());
        }
    }, [bms_servers_list]);

    const handlerVenueEditClick = id => {
        setVenueId(id);
        setIsOpen(true);
    };

    const getColor = id => {
        // Если есть ошибки на точке
        if (bmsAlarms.devProblems?.bad_status?.venue_id && bmsAlarms.devProblems.bad_status.venue_id.includes(id)) {
            return 'bms-error';
        }

        // Непросмотренная ошибка на объекте
        if (bmsAlarms.blink?.venue_id && bmsAlarms.blink.venue_id.includes(id)) {
            return 'animate-pulse bms-error';
        }

        // Ошибка на объекте
        if (bmsAlarms.alarms?.venue_id && bmsAlarms.alarms.venue_id.includes(id)) {
            return 'bms-error';
        }

        // Цвет в зависимости от статуса сервера
        return getVenueStatusColorByServerStatus(id, bmsServers);
    };

    if (venues_fetching) {
        return (
            <div className="flex items-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            {bms_venues_list.length ? (
                bms_venues_list.map(el => (
                    <div className="relative" key={`bms${el.id}`}>
                        <Link
                            to={`/bms/venues/${el.id}/systems`}
                            className={`flex h-28 w-52 flex-col items-center justify-center overflow-hidden rounded-md border-2 border-gray-10 p-2 text-white transition-colors hover:border-blue ${getColor(el.id)}`}
                        >
                            <div className="line-clamp-2 text-center text-sm font-bold uppercase leading-4">{el.title}</div>
                        </Link>

                        <div className="absolute right-1 top-1 flex flex-row items-center">
                            {isObjectEdit && (
                                <button
                                    className="flex h-6 w-6 cursor-pointer items-center justify-center text-white transition-colors hover:text-gray-800"
                                    onClick={() => handlerVenueEditClick(el.id)}
                                >
                                    <FaRegEdit />
                                </button>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex h-28 w-64 flex-col items-center justify-center overflow-hidden p-2">Нет доступных объектов</div>
            )}
        </>
    );
};
