import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../api/functions';
import { resetTasksForSupervisor, tasksForSupervisorsFetchingState, tasksForSupervisorsState } from '../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../common/Loader';
import { Droppable } from '../../../common/dnd/Droppable';
import { SvgComponent } from '../../../common/svgComponent/SvgComponent';
import { EditButton } from '../components/editSection/EditButton';
import { EditPopup } from '../components/editSection/EditPopup';
import { List } from '../components/list/List';

export const SupervisorWithTasksSlot = ({ id, name, isOpenMenu, setIsOpenMenu, activeMenuRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tasks = useSelector(tasksForSupervisorsState);
    const tasksFetching = useSelector(tasksForSupervisorsFetchingState);

    const supervisorsTasks = useMemo(() => tasks.filter(task => task.userId === id), [tasks, id]);

    const [isOpenTasks, setIsOpenTasks] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const clearTasksHandler = async () => {
        setIsLoading(true);
        const res = await dispatch(resetTasksForSupervisor({ id }));

        if (res.error) {
            toast.error(res.message, toastOptions);
        }

        setIsLoading(false);
        setIsOpenMenu(null);
    };

    return (
        <div className={`relative box-border w-56 rounded bg-white ${isOpenTasks ? 'h-fit shadow-3xl' : 'h-20'} ${isOpenMenu === id ? 'z-20' : 'z-10'}`}>
            <Droppable id={id}>
                <div className="flex w-full flex-col pl-3">
                    <div className="relative flex h-10 w-full flex-row items-center border-b border-b-gray-10 pr-3">
                        <div className="line-clamp-1 w-full text-xs font-semibold text-gray-20">{name}</div>

                        <div className="h-full shrink-0" ref={isOpenMenu === id ? activeMenuRef : null}>
                            <EditButton onClick={() => setIsOpenMenu(isOpenMenu !== id ? id : null)} />

                            <EditPopup isOpen={isOpenMenu === id}>
                                <button
                                    className="flex items-center justify-between whitespace-nowrap px-3 py-2 text-left text-xs hover:text-blue"
                                    onClick={clearTasksHandler}
                                    disabled={isLoading}
                                >
                                    {t('HOSPITALITY_CLEAR_REQUESTS')}

                                    {isLoading && <Loader width={14} height={14} />}
                                </button>
                            </EditPopup>
                        </div>
                    </div>

                    <button className="flex h-10 w-full flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="flex text-xs">
                            {t('HOSPITALITY_REQUESTS')}:{' '}
                            {tasksFetching ? (
                                <div className="ml-1">
                                    <Loader height={12} width={12} />
                                </div>
                            ) : (
                                supervisorsTasks.length
                            )}
                        </div>

                        <div className="ml-auto mr-3 h-4 w-4">
                            <SvgComponent name="chevron-down" />
                        </div>
                    </button>
                </div>

                <List isOpen={isOpenTasks} setIsOpen={setIsOpenTasks} items={supervisorsTasks} itemsTitle={t('HOSPITALITY_REQUESTS')} loading={tasksFetching} />
            </Droppable>
        </div>
    );
};
