import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../api/functions';
import { bms_units as bms_units_state, getUnits } from '../../../../../redux/slices/bms/bmsSliceDocuments';
import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

const initialState = {
    writePoint: '',
    min: '',
    max: '',
    value: '',
    unit: '',
    unitUser: '',
    isUnit: false,
};

export const NodeDataOutput = ({ setNodeStates, nodeOptions, node, setCheckPopupFields }) => {
    const dispatch = useDispatch();
    const bms_units = useSelector(bms_units_state);

    const [data, setData] = useState(initialState);

    useEffect(() => {
        if (bms_units.length === 0) {
            dispatch(getUnits());
        }
    }, []);

    useEffect(() => {
        if (node.hasOwnProperty('cn_id')) {
            setData(node.data.states[0]);
        }
    }, [node]);

    useEffect(() => {
        if (data.writePoint.datatype === 'boolean') {
            toast.error('Для вывода данных по булевой точке воспользуйтесь контроллерами', toastOptions);
            return;
        }
        if (data.value.length !== 0 && data.min.length !== 0 && data.max.length !== 0 && (data.unit.label || data.unitUser.length || data.isUnit)) {
            if (Number(data.max) <= Number(data.min)) {
                toast.error('Минимум не может быть больше или равен максимуму', toastOptions);
                return;
            }
            setCheckPopupFields(true);
        } else {
            setCheckPopupFields(false);
        }
    }, [data]);

    const handlerInputChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (data.writePoint && data.writePoint.datatype === 'float') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (data.writePoint && data.writePoint.datatype === 'int') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setData({ ...data, [type]: floatCheck[0] });
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setNodeStates([{ ...data, [type]: value[1] }]);
            setData({ ...data, [type]: value[1] });
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setData({ ...data, [type]: val });
            setNodeStates([{ ...data, [type]: val }]);
            return;
        }

        setData({ ...data, [type]: value });
        setNodeStates([{ ...data, [type]: value }]);
    };

    return (
        <div className="flex items-end gap-3 p-5">
            <SimpleSelect
                isClearable={false}
                options={nodeOptions ? nodeOptions : []}
                placeholder="Выберите точку"
                value={data.writePoint}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setData({ ...initialState, writePoint: val, value: val.value_from_data_point });
                    setNodeStates([{ ...initialState, writePoint: val, value: val.value_from_data_point }]);
                }}
                className="mr-5 w-48"
            />
            <label className="flex flex-col items-center">
                <span className="mr-2 text-sm">Значение</span>
                <input value={data.value} type="text" disabled={true} className="w-20" />
            </label>
            <label className="flex flex-col items-center">
                <span className="mr-2 text-sm">Min</span>
                <input onChange={e => handlerInputChange(e.target.value, 'min')} value={data.min} type="number" className="w-20" />
            </label>
            <label className="flex flex-col items-center">
                <span className="mr-2 text-sm">Max</span>
                <input onChange={e => handlerInputChange(e.target.value, 'max')} value={data.max} type="number" className="w-20" />
            </label>
            <label className="flex flex-col items-center">
                <span className="text-sm">Unit</span>
                <SimpleSelect
                    isClearable={false}
                    options={bms_units ? bms_units : []}
                    placeholder="Выберите единицы"
                    value={data.unit}
                    callback={val => {
                        if (val === null) {
                            return;
                        }
                        setData({ ...data, unit: val });
                        setNodeStates([{ ...data, unit: val }]);
                    }}
                    className="w-28"
                    isDisabled={data.isUnit || data.unitUser}
                />
            </label>
            <label className="flex flex-col items-center">
                <span className="mr-2 text-sm">Unit</span>
                <input
                    onChange={e => {
                        setData({ ...data, unitUser: e.target.value, unit: '' });
                        setNodeStates([{ ...data, unitUser: e.target.value, unit: '' }]);
                    }}
                    value={data.unitUser}
                    type="text"
                    className="w-20"
                    disabled={data.isUnit}
                />
            </label>
            <label className="flex w-16 flex-col items-center pb-2">
                <span className="mb-4 whitespace-nowrap text-sm">Без ед.изм.</span>
                <input
                    name="isUnit"
                    type="checkbox"
                    value={data.isUnit}
                    checked={data.isUnit}
                    onChange={e => {
                        setData({ ...data, isUnit: e.target.checked, unit: '', unitUser: '' });
                        setNodeStates([{ ...data, isUnit: e.target.checked, unit: '', unitUser: '' }]);
                    }}
                />
            </label>
        </div>
    );
};
