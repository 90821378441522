import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';

import { book } from '../../navigation/book';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import {
    deletQualificationTypeFetch,
    qualificationPeriodFetch,
    qualificationsListFetch,
    qualificationsList as qualificationsList_state,
    qualifictionsPeriodList as qualifictionsPeriodList_state,
    set_detail_open,
} from '../../redux/slices/qualificationSlice';
import { userQualificationRole as userQualificationRole_state } from '../../redux/slices/qualificationSlice';
import { ContentContainer } from '../common/ContentContainer';
import { Loader } from '../common/Loader';
import { SubHeader } from '../common/subheader/SubHeader';
import { SubHeaderLeft } from '../common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../common/subheader/SubHeaderRight';
import { Title } from '../common/subheader/Title';
import { CreateQualificationBtn } from './CreateQualificationBtn';
import { Detail } from './Detail';

export const QualificationList = () => {
    const [editBlockIsOpen, setEditBlockIsOpen] = useState(false);
    const [listeningEdit, setListeningEdit] = useState(false);
    const [qualification, setQualification] = useState(null);

    const tableEditRefQual = useRef(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const qualifictionsPeriodList = useSelector(qualifictionsPeriodList_state);
    const qualificationsList = useSelector(qualificationsList_state);
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const userQualificationRole = useSelector(userQualificationRole_state);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const listenForOutsideClick = (listening, setListening, ref, setIsOpen) => () => {
        if (listening) return;
        setListening(true);

        document.addEventListener(`click`, evt => {
            const cur = ref.current;
            if (!ref.current) return;

            const node = evt.target;
            if (cur.contains(node)) return;
            setIsOpen(null);
        });
    };

    useEffect(() => {
        if (qualifictionsPeriodList === null) {
            dispatch(qualificationPeriodFetch());
        }
    }, [dispatch, qualifictionsPeriodList]);

    useEffect(() => {
        if (userQualificationRole === undefined || userQualificationRole === 'employer') {
            navigate('/calendar');
        }
    });

    useEffect(() => {
        if (qualificationsList.length === 0) {
            dispatch(qualificationsListFetch());
        }
    }, [dispatch, qualificationsList]);

    useEffect(listenForOutsideClick(listeningEdit, setListeningEdit, tableEditRefQual, setEditBlockIsOpen));

    const columns = useMemo(
        () =>
            userQualificationRole === 'administrator' || userQualificationRole === 'manager'
                ? [
                      { Header: t('QUALIFICATION_FULL_NAME'), accessor: 'name' },
                      { Header: t('QUALIFICATION_PERIOD'), accessor: 'period_id' },
                      { Header: t('QUALIFICATION_CHANGE'), accessor: 'change_certificate' },
                      { Header: '', accessor: 'edit' },
                  ]
                : [
                      { Header: t('QUALIFICATION_FULL_NAME'), accessor: 'name' },
                      { Header: t('QUALIFICATION_PERIOD'), accessor: 'period_id' },
                      { Header: t('QUALIFICATION_CHANGE'), accessor: 'change_certificate' },
                  ],

        [lang, userQualificationRole]
    );

    const editHandler = async id => {
        setQualification(qualificationsList.find(el => el.id === id));
        await dispatch(set_detail_open(true));
    };

    const deletHandler = async id => {
        setEditBlockIsOpen(false);
        await dispatch(deletQualificationTypeFetch({ username, auth_key_calendar, id }));
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: qualificationsList === null ? [] : qualificationsList,
    });

    if (qualificationsList === null) {
        return (
            <div className="flex h-20 w-full items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.qualification_list.key)}</Title>
                </SubHeaderLeft>
                <SubHeaderRight>
                    {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && <CreateQualificationBtn />}
                </SubHeaderRight>
            </SubHeader>
            <ContentContainer>
                <Detail qualification={qualification} setQualification={setQualification} />
                <div className="w-full">
                    <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                        <thead className="absolute top-16 w-0 overflow-hidden text-left uppercase text-black md:sticky md:z-10 md:w-full md:overflow-visible">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        return (
                                            <th
                                                {...column.getHeaderProps()}
                                                className={`border-y border-l border-gray-10 bg-white px-2 py-5 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:pr-4 ${
                                                    userQualificationRole === 'administrator' || userQualificationRole === 'manager'
                                                        ? 'last:border-l-0'
                                                        : 'last:border-l'
                                                }`}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="mb-4 block overflow-hidden rounded-lg border border-gray-10 md:mb-0 md:table-row md:overflow-visible md:border-0"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';

                                            if (cell.column.id === 'edit') {
                                                additionally = (
                                                    <div className="relative" ref={editBlockIsOpen === cell.row.original.id ? tableEditRefQual : null}>
                                                        {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && (
                                                            <button
                                                                className="hidden items-center justify-center rounded-full border-0 bg-transparent hover:bg-gray-100 md:flex"
                                                                type="button"
                                                                onClick={() =>
                                                                    editBlockIsOpen === cell.row.original.id
                                                                        ? setEditBlockIsOpen(false)
                                                                        : setEditBlockIsOpen(cell.row.original.id)
                                                                }
                                                            >
                                                                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                                                    <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
                                                                </svg>
                                                            </button>
                                                        )}
                                                        <div
                                                            className={`relative right-0 z-10 transition-opacity md:absolute ${
                                                                editBlockIsOpen === cell.row.original.id ? 'md:showed md:opacity-100' : 'md:hidden md:opacity-0'
                                                            }`}
                                                        >
                                                            {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && (
                                                                <button
                                                                    className="w-28 cursor-pointer rounded-sm border-0 bg-white py-2 pl-3 pr-4 text-xs font-normal text-black shadow-3xl hover:bg-gray-100"
                                                                    type="button"
                                                                    onClick={() => editHandler(cell.row.original.id)}
                                                                >
                                                                    {t('ADM_PLANNER_EDIT_BTN')}
                                                                </button>
                                                            )}
                                                            {userQualificationRole === 'administrator' && (
                                                                <button
                                                                    className="w-28 cursor-pointer rounded-sm border-0 bg-white py-2 pl-3 pr-4 text-xs font-normal text-red-600 shadow-3xl hover:bg-gray-100"
                                                                    type="button"
                                                                    onClick={() => deletHandler(cell.row.original.id)}
                                                                >
                                                                    {t('CALENDAR_BTN_DEL')}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (cell.column.id === 'change_certificate') {
                                                additionally = <div>{cell.row.original.change_certificate === false ? t('NO') : t('YES')}</div>;
                                            }

                                            if (cell.column.id === 'period_id') {
                                                const label = qualifictionsPeriodList?.find(el => el.value === cell.row.original.period_id);
                                                additionally = <div>{label?.label}</div>;
                                            }

                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-label={cell.column.Header}
                                                    className={`flex justify-between border-b border-l border-gray-10 bg-white px-2 py-5 text-right font-medium before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 last:pl-1 md:table-cell md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-1 ${
                                                        rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    }`}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </ContentContainer>
        </>
    );
};
