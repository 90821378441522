import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SubHeader } from '../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../components/common/subheader/SubHeaderLeft';
import { Title } from '../components/common/subheader/Title';
// @ts-ignore
import { Button } from '../components/ui/button/Button.tsx';
import { Checkbox } from '../components/ui/checkbox/Checkbox';
import { DatePicker } from '../components/ui/datePicker/DatePicker';
import { FileSelector } from '../components/ui/fileSelector/FileSelector';
import { MultiSelect } from '../components/ui/multiSelect/MultiSelect';
import { RadioButton } from '../components/ui/radioButton/RadioButton';
import { SimpleSelect } from '../components/ui/simpleSelect/SimpleSelect';

export const UIPage: FC = () => {
    useTranslation();

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>User Interface</Title>
                </SubHeaderLeft>
            </SubHeader>

            <div className="mx-auto flex w-full flex-col gap-5 p-4 sm:p-6">
                {/*Button*/}
                <div className="border-b border-b-neutral-200 pb-5">
                    <div className="mb-4 text-xl font-semibold text-black">Button</div>

                    <div className="flex flex-col gap-8">
                        <div className="flex flex-wrap gap-6">
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div>Info</div>
                                <Button type="info">info</Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div>Loading</div>
                                <Button type="info" loading>
                                    info
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div>Disabled</div>
                                <Button type="info" disabled>
                                    info
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading Disabled</div>
                                <Button type="info" loading disabled>
                                    info
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-6">
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Success</div>
                                <Button type="success">success</Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading</div>
                                <Button type="success" loading>
                                    success
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Disabled</div>
                                <Button type="success" disabled>
                                    success
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading Disabled</div>
                                <Button type="success" loading disabled>
                                    success
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-6">
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Light</div>
                                <Button type="light">light</Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading</div>
                                <Button type="light" loading>
                                    light
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Disabled</div>
                                <Button type="light" disabled>
                                    light
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading Disabled</div>
                                <Button type="light" loading disabled>
                                    light
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-6">
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Cancel</div>
                                <Button type="cancel">cancel</Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading</div>
                                <Button type="cancel" loading>
                                    cancel
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Disabled</div>
                                <Button type="cancel" disabled>
                                    cancel
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading Disabled</div>
                                <Button type="cancel" loading disabled>
                                    cancel
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-6">
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Link</div>
                                <Button type="link">link</Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading</div>
                                <Button type="link" loading>
                                    link
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Disabled</div>
                                <Button type="link" disabled>
                                    link
                                </Button>
                            </div>
                            <div className="flex w-28 flex-col gap-2 text-sm">
                                <div className="whitespace-nowrap">Loading Disabled</div>
                                <Button type="link" loading disabled>
                                    link
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Checkbox*/}
                <div className="border-b border-b-neutral-200 pb-5">
                    <div className="mb-4 text-xl font-semibold text-black">Checkbox</div>

                    <div className="flex flex-col gap-6">
                        <Checkbox />
                        <Checkbox title="checkbox with title" />
                        <Checkbox title="checked checkbox" checked />
                        <Checkbox title="disabled checkbox" disabled />
                        <Checkbox title="disabled checked checkbox" disabled checked />
                    </div>
                </div>

                {/*Radio button*/}
                <div className="border-b border-b-neutral-200 pb-5">
                    <div className="mb-4 text-xl font-semibold text-black">Radio button</div>

                    <div className="flex flex-col gap-6">
                        <RadioButton name="radio" value="3" />
                        <RadioButton title="radio button with title" name="radio" value="2" />
                        <RadioButton title="checked radio button" checked name="radio" value="1" />
                        <RadioButton title="disabled radio button" disabled />
                        <RadioButton title="disabled checked radio button" disabled checked />
                    </div>
                </div>

                {/*File Selector*/}
                <div className="border-b border-b-neutral-200 pb-5">
                    <div className="mb-4 text-xl font-semibold text-black">File Selector</div>

                    <FileSelector callback={files => console.log(files)} />
                </div>

                {/*Simple Select*/}
                <div className="border-b border-b-neutral-200 pb-5">
                    <div className="mb-4 text-xl font-semibold text-black">Simple Select</div>

                    <SimpleSelect
                        value={[]}
                        options={[
                            { value: '1', label: 'Опция с иконкой', svgName: 'document' },
                            { value: '2', label: 'Опция 1' },
                            { value: '3', label: 'Опция 2' },
                            { value: '4', label: 'Опция 3' },
                        ]}
                        callback={value => console.log('value', value)}
                        className="w-96"
                    />
                </div>

                {/*Multi Select*/}
                <div className="border-b border-b-neutral-200 pb-5">
                    <div className="mb-4 text-xl font-semibold text-black">Multi Select</div>

                    <MultiSelect
                        value={[]}
                        options={[
                            { value: '1', label: 'Опция 1' },
                            { value: '2', label: 'Опция 2' },
                            { value: '3', label: 'Опция 3' },
                            { value: '4', label: 'Опция 4' },
                        ]}
                        callback={value => console.log('value', value)}
                        className="w-96"
                    />
                </div>

                {/*Date Picker*/}
                <div>
                    <div className="mb-4 text-xl font-semibold text-black">Date Picker</div>
                    <div className="flex flex-row gap-6">
                        <div className="flex flex-col gap-2 text-sm">
                            <div className="whitespace-nowrap">Default date picker</div>
                            <DatePicker callback={value => console.log('value', value)} />
                        </div>

                        <div className="flex flex-col gap-2 text-sm">
                            <div className="whitespace-nowrap">Selected date</div>
                            <DatePicker callback={value => console.log('value', value)} selectedDate={new Date()} />
                        </div>

                        <div className="flex flex-col gap-2 text-sm">
                            <div className="whitespace-nowrap">Date picker with time</div>
                            <DatePicker callback={value => console.log('value', value)} showTimeSelect={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
