import { useEffect, useState } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getVenueStatusColorByServerStatus } from '../../../api/functions/bms/getVenueStatusByServerStatus';
import { useCheckRole } from '../../../hooks/useCheckRole';
import { set_bms_doc_list } from '../../../redux/slices/bms/bmsSliceDocuments';
import { bmsAlarmsState, bms_servers_state } from '../../../redux/slices/bms/bmsSliceServers';
import {
    active_system as active_system_state,
    bms_systems_fetching as bms_systems_fetching_state,
    clear_system_list,
    getBMSSystemList,
    removeBMSSystemInVenue,
} from '../../../redux/slices/bms/bmsSliceSystems';
import { Loader } from '../../common/Loader';
import { PopupActionConfirmation } from '../mainPage/PopupActionConfirmation';

export const SystemList = () => {
    const dispatch = useDispatch();
    const { venueId } = useParams();

    const bms_systems_fetching = useSelector(bms_systems_fetching_state);
    const active_system = useSelector(active_system_state);
    const bmsServers = useSelector(bms_servers_state);
    const bmsAlarms = useSelector(bmsAlarmsState);

    const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
    const [selectedSystem, setSelectedSystem] = useState(null);

    const isObjectDirectionsDelete = useCheckRole('bms', 'bms.object_directions.del');

    useEffect(() => {
        dispatch(set_bms_doc_list([]));

        if (!active_system.length) {
            dispatch(getBMSSystemList({ venueId }));
        }

        return () => dispatch(clear_system_list());
    }, [venueId]);

    const handlerSystemRemoveClick = systemId => {
        dispatch(removeBMSSystemInVenue({ object_id: venueId, direction_id: systemId }));

        setIsOpenConfirmationPopup(false);
    };

    const getColor = id => {
        // Если есть ошибки на точке
        if (bmsAlarms.devProblems?.bad_status?.system_id && bmsAlarms.devProblems.bad_status.system_id.includes(`${venueId}_${id}`)) {
            return 'bms-error';
        }

        // Непросмотренная ошибка на объекте
        if (bmsAlarms.blink?.system_id && bmsAlarms.blink.system_id.includes(`${venueId}_${id}`)) {
            return 'animate-pulse bms-error';
        }

        // Ошибка на объекте
        if (bmsAlarms.alarms?.system_id && bmsAlarms.alarms.system_id.includes(`${venueId}_${id}`)) {
            return 'bms-error';
        }

        // Цвет в зависимости от статуса сервера
        return getVenueStatusColorByServerStatus(+venueId, bmsServers);
    };

    if (bms_systems_fetching) {
        return (
            <div className="flex h-28 w-52 items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            {active_system.length ? (
                active_system.map(system => (
                    <div className="relative" key={`bms${system.id}`}>
                        <Link
                            to={`/bms/venues/${venueId}/system/${system.id}/documents`}
                            className={`flex h-28 w-52 flex-col items-center justify-center overflow-hidden rounded-md border-2 border-gray-10 p-2 text-white transition-colors hover:border-blue ${getColor(
                                system.id
                            )}`}
                        >
                            {system.title && <div className="line-clamp-2 text-center text-sm font-bold uppercase leading-4">{system.title}</div>}
                        </Link>

                        <div className="absolute right-1 top-1 flex flex-row items-center">
                            {isObjectDirectionsDelete && (
                                <button
                                    className="flex h-6 w-6 cursor-pointer items-center justify-center text-white transition-colors hover:text-gray-800"
                                    onClick={() => {
                                        setIsOpenConfirmationPopup(true);
                                        setSelectedSystem(system);
                                    }}
                                >
                                    <MdDeleteOutline size={20} />
                                </button>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex h-28 w-64 flex-col items-center justify-center overflow-hidden p-2">Нет выбранных разделов</div>
            )}

            {isOpenConfirmationPopup && (
                <PopupActionConfirmation
                    isOpen={isOpenConfirmationPopup}
                    setIsOpen={setIsOpenConfirmationPopup}
                    callback={handlerSystemRemoveClick}
                    element={selectedSystem}
                    title="Вы действительно хотите удалить раздел"
                />
            )}
        </>
    );
};
