let src;

if (process.env.REACT_APP_MODE === 'prod') {
    src = 'https://kaizen-fm.ru';
} else {
    src = new URLSearchParams(window.location.search).get('src');
    if (src) {
        src = /^https?:\/\//i.test(src) ? src : `https://${src}`;
        localStorage.setItem('src', src);
    } else {
        src = localStorage.getItem('src') || 'https://saas-test.becar.ru';
    }
}

export const url = src;

export const root = url + '/index.php';
