import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../api/functions';
import { autoDistributeTaskForSlots, autoDistributeTaskForSupervisors } from '../../../../redux/slices/hospitalitySlice';
import { Button } from '../../../ui/button/Button.tsx';

export const DistributeButton = ({ sectionId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const distributeHandler = async () => {
        setIsLoading(true);

        let result;

        if (sectionId === 2) {
            result = await dispatch(autoDistributeTaskForSlots());
        }
        if (sectionId === 3) {
            result = await dispatch(autoDistributeTaskForSupervisors());
        }

        if (result) {
            if (result.error) {
                toast.error(result.message, toastOptions);
            }
        }

        setIsLoading(false);
    };

    return (
        <Button type="success" className="w-full" onClick={distributeHandler} loading={isLoading} disabled={sectionId === 3}>
            {t('HOSPITALITY_DISTRIBUTE')}
        </Button>
    );
};
