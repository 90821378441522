import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { bmsAPI } from '../../../api/modules/bmsAPI';
import { store } from '../../store.ts';
import { clear_bms_doc_detail } from './bmsSliceEventLog.js';
import { setBmsAlarms, set_bms_error_servers_arr, set_bms_server_error, set_bms_servers_state } from './bmsSliceServers.js';

export const bmsSliceDocuments = createSlice({
    name: 'bmsDocuments',

    initialState: {
        lib: [],
        bms_units: [],
        nodes_list: [],
        bms_doc_list: [],
        documentDataPoints: [],
        historyRemovedNodes: [],
        selectedNodesForCopy: [],
        document: {
            id: null,
            nodes: null,
            controls: null,
        },
        bms_chart_points: {},
        selectedDocumentForCopy: null,
        lib_fetching: false,
        nodes_fetching: false,
        create_bms_fetching: false,
        bms_doc_list_fetching: false,
        bms_detail_nodes_fetching: false,
    },

    reducers: {
        set_lib: (state, action) => {
            state.lib = action.payload;
        },
        set_lib_fetching: (state, action) => {
            state.lib_fetching = action.payload;
        },
        set_bms_units: (state, action) => {
            state.bms_units = action.payload;
        },
        set_nodes_list: (state, action) => {
            state.nodes_list = action.payload;
        },
        set_bms_doc_list: (state, action) => {
            state.bms_doc_list = action.payload;
        },
        update_bms_doc_list: (state, action) => {
            const doc = state.bms_doc_list.find(doc => doc.id === action.payload.id);

            if (doc) {
                state.bms_doc_list = state.bms_doc_list.map(doc => {
                    if (doc.id === action.payload.id) {
                        return { ...doc, ...action.payload };
                    }

                    return doc;
                });
            } else {
                state.bms_doc_list = [action.payload, ...state.bms_doc_list];
            }
        },
        remove_bms_doc: (state, action) => {
            state.bms_doc_list = state.bms_doc_list.filter(doc => doc.id !== action.payload);
        },
        set_bms_doc_list_fetching: (state, action) => {
            state.bms_doc_list_fetching = action.payload;
        },
        setBmsDocumentDataPoints: (state, action) => {
            state.documentDataPoints = action.payload;
        },
        set_document: (state, action) => {
            state.document = { ...state.document, ...action.payload };
        },
        remove_document: state => {
            state.document = bmsSlice.getInitialState().document;
        },
        update_bms_document_node: (state, action) => {
            state.document = {
                ...state.document,
                nodes: state.document.nodes.map(node => (node.cn_id !== action.payload.cn_id ? node : action.payload)),
            };
        },
        add_bms_document_node: (state, action) => {
            state.document = { ...state.document, nodes: [...state.document.nodes, action.payload] };
        },
        remove_bms_document_node: (state, action) => {
            state.document = {
                ...state.document,
                nodes: state.document.nodes.filter(node => node.cn_id !== action.payload),
            };
        },
        set_control: (state, action) => {
            state.document = state.document = {
                ...state.document,
                controls: [...state.document.controls, action.payload],
            };
        },
        set_control_value: (state, action) => {
            state.document = {
                ...state.document,
                controls: state.document.controls.map(control =>
                    control.cn_id !== action.payload.cn_id ? control : { ...control, value: action.payload.value }
                ),
            };
        },
        update_document_control: (state, action) => {
            state.document = {
                ...state.document,
                controls: state.document.controls.map(control =>
                    control.cn_id !== action.payload.cn_id ? control : { ...control, name: action.payload.data.title, data: action.payload.data }
                ),
            };
        },
        remove_bms_document_control: (state, action) => {
            state.document = {
                ...state.document,
                controls: state.document.controls.filter(node => node.cn_id !== action.payload),
            };
        },
        set_bms_chart_points: (state, action) => {
            state.bms_chart_points = action.payload;
        },
        set_bms_detail_nodes_fetching: (state, action) => {
            state.bms_detail_nodes_fetching = action.payload;
        },
        set_nodes_fetching: (state, action) => {
            state.nodes_fetching = action.payload;
        },
        set_create_bms_fetching: (state, action) => {
            state.create_bms_fetching = action.payload;
        },
        setHistoryRemovedNodes: (state, action) => {
            state.historyRemovedNodes.push(action.payload);
            state.document = {
                ...state.document,
                nodes: state.document.nodes.map(node => (node.cn_id === action.payload ? { ...node, removed: true } : node)),
            };
        },
        clearHistoryRemovedNodes: state => {
            state.historyRemovedNodes = [];
        },
        returnRemovedNode: state => {
            const returnedNode = state.historyRemovedNodes.pop();
            state.document = {
                ...state.document,
                nodes: state.document.nodes.map(node => (node.cn_id === returnedNode ? { ...node, removed: false } : node)),
            };
        },
        setSelectedNodesForCopy: (state, action) => {
            state.selectedNodesForCopy = action.payload;
        },
        setSelectedDocumentForCopy: (state, action) => {
            state.selectedDocumentForCopy = action.payload;
        },
        addCopiedNodesInDocument: (state, action) => {
            state.document = { ...state.document, nodes: [...state.document.nodes, ...action.payload] };
        },
        addCopiedDocument: (state, action) => {
            state.bms_doc_list = [...state.bms_doc_list, action.payload];
        },
        checkCopyNode: (state, action) => {
            state.document = {
                ...state.document,
                nodes: state.document.nodes.map(node => (node.cn_id === action.payload ? { ...node, data: { ...node.data, isNew: false } } : node)),
            };
        },
        setFeedbackButtonState: (state, action) => {
            state.document = {
                ...state.document,
                controls: state.document.controls.map(control =>
                    control.cn_id === action.payload.cn_id
                        ? {
                              ...control,
                              data: {
                                  ...control.data,
                                  controlState: { ...control.data.controlState, state: action.payload.controlState },
                              },
                          }
                        : control
                ),
            };
        },
    },
});

export const {
    set_lib,
    set_control,
    set_document,
    set_bms_units,
    checkCopyNode,
    set_nodes_list,
    remove_bms_doc,
    remove_document,
    set_lib_fetching,
    set_bms_doc_list,
    returnRemovedNode,
    addCopiedDocument,
    set_control_value,
    set_nodes_fetching,
    update_bms_doc_list,
    set_bms_chart_points,
    add_bms_document_node,
    setHistoryRemovedNodes,
    setSelectedNodesForCopy,
    update_document_control,
    set_create_bms_fetching,
    addCopiedNodesInDocument,
    remove_bms_document_node,
    update_bms_document_node,
    setBmsDocumentDataPoints,
    clearHistoryRemovedNodes,
    set_bms_doc_list_fetching,
    setSelectedDocumentForCopy,
    remove_bms_document_control,
    set_bms_detail_nodes_fetching,
    setFeedbackButtonState,
} = bmsSliceDocuments.actions;

// Создание документа
export const createBMSDoc =
    ({ data }) =>
    async () => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.createBMSDoc.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    return {
                        id: res.data.id,
                        background_image: res.data.background_image,
                        error: false,
                        msg: '',
                    };
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method createBMSDoc \n ${e}`, toastOptions);
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Обновление документа
export const updateBMSDoc =
    ({ data }) =>
    async () => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.updateBMSDoc.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    return {
                        background_image: res.data.background_image,
                        id: res.data.id,
                        error: false,
                        msg: '',
                    };
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method updateBMSDoc \n ${e}`, toastOptions);
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Удаление документа из системы
export const removeBMSDoc =
    ({ id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSDoc.fetch(username, auth_key_calendar, id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_doc(id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSDoc \n ${e}`, toastOptions);
        }
    };

// Получение списка элементов нод, размещенных в рамках документа
export const getBMSDocNodes =
    ({ data }) =>
    async dispatch => {
        dispatch(set_bms_detail_nodes_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSDocNodes.fetch(username, auth_key_calendar, { doc_id: data.doc_id });

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const dataElements = res.data.filter(el => !el.is_control);
                    const dataControls = res.data.filter(el => el.is_control);

                    dispatch(
                        set_document({
                            nodes: dataElements.map(node => ({
                                ...node,
                                lib_data: JSON.parse(node.lib_data),
                                data: JSON.parse(node.data),
                                position: JSON.parse(node.position),
                                title: node.name,
                            })),
                            controls: dataControls.map(control => ({
                                ...control,
                                lib_data: JSON.parse(control.lib_data),
                                data: JSON.parse(control.data),
                            })),
                        })
                    );
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocNodes \n ${e}`, toastOptions);
        } finally {
            dispatch(set_bms_detail_nodes_fetching(false));
        }
    };

// Получение списка элементов нод, размещенных в рамках документа таймаут
export const getBMSDocNodesTimeout =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        try {
            const response = await bmsAPI.getBMSDocNodes.fetch(username, auth_key_calendar, { doc_id: data.doc_id });

            if (response.status === 200) {
                const result = await response.json();

                if (result.hasOwnProperty('data')) {
                    const dataElements = result.data.filter(el => !el.is_control);
                    const dataControls = result.data.filter(el => el.is_control);

                    dispatch(
                        set_document({
                            nodes: dataElements.map(node => ({
                                ...node,
                                lib_data: JSON.parse(node.lib_data),
                                data: JSON.parse(node.data),
                                position: JSON.parse(node.position),
                                title: node.name,
                            })),
                            controls: dataControls.map(control => ({
                                ...control,
                                lib_data: JSON.parse(control.lib_data),
                                data: JSON.parse(control.data),
                            })),
                        })
                    );
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocNodes \n ${e}`, toastOptions);
        }
    };

// Получение списка нод, которые могут быть использованы при размещении в документе
export const getBMSNodesList =
    ({ docId }) =>
    async dispatch => {
        dispatch(set_nodes_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSNodes.fetch(username, auth_key_calendar, docId);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_nodes_list(res.data.map(el => ({ ...el, lib_data: JSON.parse(el.lib_data) }))));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSNodesList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_nodes_fetching(false));
        }
    };

// Получение списка элементов библиотеки
export const getBMSLib = () => async dispatch => {
    dispatch(set_lib_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getBMSLib.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                const data = res.data.direction.map(direction => ({
                    title: direction.title,
                    types: [
                        {
                            title: 'Оборудование',
                            groups: direction.groups
                                .filter(group => group.title !== 'Элементы управления')
                                .map(group => ({
                                    title: group.title,
                                    items: group.items.map(item => ({
                                        ...item,
                                        lib_data: JSON.parse(item.data),
                                        name: item.title,
                                    })),
                                })),
                        },
                        {
                            title: 'Элементы управления',
                            groups: direction.groups
                                .filter(group => group.title === 'Элементы управления')
                                .map(group => ({
                                    title: group.title,
                                    items: group.items.map(item => ({
                                        ...item,
                                        lib_data: JSON.parse(item.data),
                                        name: item.title,
                                    })),
                                })),
                        },
                    ],
                }));

                dispatch(set_lib(data));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSLib \n ${e}`, toastOptions);
    } finally {
        dispatch(set_lib_fetching(false));
    }
};

// получение единиц измерения
export const getUnits = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getUnits.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();
            const data = res.data.map((el, idx) => ({ value: idx, label: el }));
            dispatch(set_bms_units(data));
        }
    } catch (e) {
        toast.error(`Method getUnits \n ${e}`, toastOptions);
    }
};

// Получение списка документов
export const getBMSDocList =
    ({ object_id, direction_id }) =>
    async dispatch => {
        dispatch(clear_bms_doc_detail());
        dispatch(set_bms_doc_list_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;

        try {
            const response = await bmsAPI.getBMSDocList.fetch(username, auth_key_calendar, lang, object_id, direction_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_doc_list(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_bms_doc_list_fetching(false));
        }
    };

// Получение списка нод, которые уже используются в документе
export const getBMSDocDataPointsList =
    ({ docId }) =>
    async dispatch => {
        dispatch(set_nodes_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        try {
            const response = await bmsAPI.getBMSDocDataPoints.fetch(username, auth_key_calendar, docId);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(setBmsDocumentDataPoints(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocDataPointsList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_nodes_fetching(false));
        }
    };

// Обновление данных ноды
export const updateNodeData =
    ({ nodesArr }) =>
    async dispatch => {
        dispatch(set_create_bms_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            for (let i = 0; i < nodesArr.length; i++) {
                const response = await bmsAPI.updateBMSDocNodeData.fetch(username, auth_key_calendar, nodesArr[i]);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const data = {
                            ...nodesArr[i],
                            data: JSON.parse(nodesArr[i].data),
                            position: JSON.parse(nodesArr[i].position),
                        };

                        dispatch(update_bms_document_node(data));
                    } else {
                        toast.error(res.msg, toastOptions);
                    }
                }
            }
            return { error: false, msg: '' };
        } catch (e) {
            toast.error(`Method updateNodeData \n ${e}`, toastOptions);
        } finally {
            dispatch(set_create_bms_fetching(false));
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

export const updateControlData =
    ({ updateNode }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        try {
            const response = await bmsAPI.updateBMSDocNodeData.fetch(username, auth_key_calendar, updateNode);
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 0) {
                    const data = {
                        ...updateNode,
                        data: JSON.parse(updateNode.data),
                    };

                    dispatch(update_document_control(data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
            return { error: false, msg: '' };
        } catch (e) {
            toast.error(`Method updateControlData \n ${e}`, toastOptions);
        }
        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Удаление ноды из документа
export const removeBMSDocNode =
    ({ cn_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSDocNode.fetch(username, auth_key_calendar, cn_id);

            if (response.status === 200) {
                const res = await response.json();
                dispatch(remove_bms_document_node(cn_id));

                if (res.code !== 0) {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSDocNode \n ${e}`, toastOptions);
        }
    };

// Удаление контрола из документа
export const removeBMSDocControl =
    ({ cn_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSDocNode.fetch(username, auth_key_calendar, cn_id);

            if (response.status === 200) {
                const res = await response.json();
                dispatch(remove_bms_document_control(cn_id));
                toast.success('Изменения сохранены', toastOptions);
                if (res.code !== 0) {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSDocNode \n ${e}`, toastOptions);
        }
    };

// получение значений нод из документа для графика
export const getBMSDataChart =
    ({ data }) =>
    async dispatch => {
        dispatch(set_nodes_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSDataChart.fetch(username, auth_key_calendar, data);
            if (response.status === 200) {
                const result = await response.json();

                if (result.code === 0) {
                    dispatch(set_bms_chart_points(result.data));
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDataChart \n ${e}`, toastOptions);
        } finally {
            dispatch(set_nodes_fetching(false));
        }
    };

// Добавление ноды (оборудования) к документу
export const addBMSDocNode =
    ({ nodeObj }) =>
    async dispatch => {
        dispatch(set_create_bms_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.addBMSDocNode.fetch(username, auth_key_calendar, nodeObj);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    return {
                        cn_id: res.data.cn_id,
                        width: res.data.size.width,
                        height: res.data.size.height,
                    };
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSDocNode \n ${e}`, toastOptions);
        } finally {
            dispatch(set_create_bms_fetching(false));
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Передача значений контроллеров
export const setBMSControlValues =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.setBMSControlValue.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (data.flag === 'list') {
                        const value = { ...data, value: JSON.parse(data.value).value };
                        dispatch(set_control_value(value));
                    } else {
                        dispatch(set_control_value(data));
                    }
                    return res.data;
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method setBMSControlValues \n ${e}`, toastOptions);
        }
    };

// копирование ноды
export const copyBMSDocNode =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.copyDocNode.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const result = await response.json();
                console.log('result:', result);

                if (result.data.success) {
                    if (result.data.result_nodes.length > 0) {
                        const copyNodes = result.data.result_nodes.map(node => ({
                            ...node,
                            lib_data: JSON.parse(node.lib_data),
                            data: JSON.parse(node.data),
                            position: JSON.parse(node.position),
                            title: node.name,
                        }));
                        dispatch(addCopiedNodesInDocument(copyNodes));
                    }
                } else {
                    toast.error(result.data.error, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method copyBMSDocNode \n ${e}`, toastOptions);
        }
    };

// копирование документа
export const copyBMSDocument =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.copyDocument.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const result = await response.json();
                console.log('result:', result);

                if (result.data.success) {
                    dispatch(addCopiedDocument(result.data.result));
                    dispatch(setSelectedDocumentForCopy(null));
                } else {
                    toast.error(result.data.error, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method copyBMSDocument \n ${e}`, toastOptions);
        }
    };

// выгрузка трендов
export const uploadChartTrends =
    ({ data }) =>
    async () => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        try {
            const response = await bmsAPI.getBMSDataChart.fetch(username, auth_key_calendar, data);
            if (response.status === 200) {
                const blob = await response.blob();
                if (window.navigator.msSaveOrOpenBlob) {
                    //Internet Explorer
                    window.navigator.msSaveOrOpenBlob(
                        new Blob([blob], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                        'report.csv'
                    );
                } else {
                    const link = document.createElement('a');
                    document.body.appendChild(link);
                    link.download = 'report.csv';
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } catch (e) {
            toast.error(`Method uploadChartTrends \n ${e}`, toastOptions);
        }
    };

// сброс тревог
export const resetBMSAlarm =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.resetAlarm.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const result = await response.json();

                if (result.data.result.servers.length) {
                    dispatch(set_bms_servers_state(result.data.result.servers));

                    const servers_error = result.data.result.servers.filter(server => server.state_error);

                    if (servers_error.length > 0) {
                        dispatch(set_bms_server_error(true));
                        dispatch(set_bms_error_servers_arr(servers_error));

                        return servers_error;
                    } else {
                        dispatch(set_bms_server_error(false));
                        dispatch(set_bms_error_servers_arr([]));
                    }
                } else {
                    dispatch(set_bms_server_error(false));
                    dispatch(set_bms_error_servers_arr([]));
                }
                if (Object.keys(result.data.result.alarms).length > 0 || Object.keys(result.data.result.blink).length > 0) {
                    dispatch(setBmsAlarms({ alarms: result.data.result.alarms, blink: result.data.result.blink }));
                }
            }
        } catch (e) {
            toast.error(`Method resetBMSAlarm \n ${e}`, toastOptions);
        }
    };

// обновить статус контрола ОС
export const updateBMSFeedbackControl =
    ({ cnId, controlState }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            await bmsAPI.updateBMSFeedbackControl.fetch(username, auth_key_calendar, cnId);
            await dispatch(setFeedbackButtonState({ cn_id: cnId, controlState }));
        } catch (e) {
            toast.error(`Method updateBMSFeedbackControl \n ${e}`, toastOptions);
        }
    };

export const lib = state => state.bmsDocuments.lib;
export const bms_units = state => state.bmsDocuments.bms_units;
export const nodes_list = state => state.bmsDocuments.nodes_list;
export const documentState = state => state.bmsDocuments.document;
export const lib_fetching = state => state.bmsDocuments.lib_fetching;
export const bms_doc_list = state => state.bmsDocuments.bms_doc_list;
export const nodesFetchingState = state => state.bmsDocuments.nodes_fetching;
export const bmsChartPointsState = state => state.bmsDocuments.bms_chart_points;
export const createBmsFetchingState = state => state.bmsDocuments.create_bms_fetching;
export const bms_doc_list_fetching = state => state.bmsDocuments.bms_doc_list_fetching;
export const historyRemovedNodesState = state => state.bmsDocuments.historyRemovedNodes;
export const selectedNodesForCopyState = state => state.bmsDocuments.selectedNodesForCopy;
export const bmsDocumentDataPointsState = state => state.bmsDocuments.documentDataPoints;
export const bms_detail_nodes_fetching = state => state.bmsDocuments.bms_detail_nodes_fetching;
export const selectedDocumentForCopyState = state => state.bmsDocuments.selectedDocumentForCopy;

export default bmsSliceDocuments.reducer;
