import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../api/functions';
import { assignWorkerToSlot, resetTasksForSlot, tasksFetchingState, tasksState } from '../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../common/Loader';
import { Droppable } from '../../../common/dnd/Droppable';
import { SvgComponent } from '../../../common/svgComponent/SvgComponent';
import { CleanerSelectionSection } from '../components/cleanerSelectionSection/CleanerSelectionSection';
import { EditButton } from '../components/editSection/EditButton';
import { EditPopup } from '../components/editSection/EditPopup';
import { List } from '../components/list/List';

export const CleanerWithTasksSlot = ({ item, isOpenMenu, setIsOpenMenu, isOpenCleanerSelection, setIsOpenCleanerSelection, activeMenuRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tasks = useSelector(tasksState);
    const tasksFetching = useSelector(tasksFetchingState);

    const cleanerTasks = useMemo(() => tasks.filter(task => task.slotId === item.id), [tasks, item]);

    const [isOpenTasks, setIsOpenTasks] = useState(false);
    const [assignWorkerToSlotFetching, setAssignWorkerToSlotFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const clearTasksHandler = async () => {
        setIsLoading(true);

        const res = await dispatch(resetTasksForSlot({ id: item.id }));

        if (res.error) {
            toast.error(res.message, toastOptions);
        }

        setIsLoading(false);
        setIsOpenMenu(null);
    };

    const setCleanerHandler = async e => {
        setAssignWorkerToSlotFetching(true);
        setIsOpenCleanerSelection(null);
        const res = await dispatch(assignWorkerToSlot({ userId: e.currentTarget.value, slotId: item.id }));
        setAssignWorkerToSlotFetching(false);

        if (res.error) {
            toast.error(res.messageKey ? t(res.messageKey) : res.message, toastOptions);
        }
    };

    return (
        <div
            className={`relative box-border w-56 rounded bg-white ${isOpenTasks ? 'shadow-3xl' : ''} ${isOpenCleanerSelection === item.id || isOpenTasks ? 'h-fit' : 'h-28'} ${isOpenMenu === item.id || isOpenCleanerSelection === item.id ? 'z-20' : 'z-10'}`}
        >
            <Droppable id={item.id}>
                <div className="flex h-28 w-full flex-col pl-3">
                    <div className="flex h-[72px] shrink-0 flex-col border-b border-b-gray-10 pr-3 pt-3">
                        <div className="flex w-full flex-row items-center">
                            {assignWorkerToSlotFetching ? (
                                <Loader width={12} height={12} />
                            ) : item.supervisorName ? (
                                <div className="line-clamp-1 w-full text-xs font-semibold text-gray-20">{item.supervisorName}</div>
                            ) : (
                                <div className="line-clamp-1 w-full text-xs font-medium text-gray-500">{t('HOSPITALITY_SUPERVISOR')}</div>
                            )}

                            <div className="h-full shrink-0" ref={isOpenMenu === item.id ? activeMenuRef : null}>
                                <EditButton onClick={() => setIsOpenMenu(isOpenMenu !== item.id ? item.id : null)} />

                                <EditPopup isOpen={isOpenMenu === item.id}>
                                    <button
                                        className="flex items-center justify-between whitespace-nowrap px-3 py-2 text-left text-xs hover:text-blue"
                                        onClick={clearTasksHandler}
                                        disabled={isLoading}
                                    >
                                        {t('HOSPITALITY_CLEAR_REQUESTS')}

                                        {isLoading && <Loader width={14} height={14} />}
                                    </button>
                                </EditPopup>
                            </div>
                        </div>

                        <button
                            className="flex h-full w-full flex-row items-center justify-start gap-2 text-gray-500"
                            onClick={() => {
                                setIsOpenTasks(false);
                                setIsOpenCleanerSelection(item.id);
                            }}
                        >
                            <div className="h-3.5 w-3.5 shrink-0">
                                <SvgComponent name="search" />
                            </div>

                            {assignWorkerToSlotFetching ? (
                                <Loader width={12} height={12} />
                            ) : item.cleanerName ? (
                                <span className="line-clamp-1 text-left text-xs font-semibold text-gray-20">{item.cleanerName}</span>
                            ) : (
                                <span className="line-clamp-1 text-left text-xs font-medium text-gray-500">{t('HOSPITALITY_ADD_MAID')}</span>
                            )}
                        </button>
                    </div>

                    <button className="flex h-full w-full flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="flex text-xs">
                            {t('HOSPITALITY_REQUESTS')}:{' '}
                            {tasksFetching ? (
                                <div className="ml-1">
                                    <Loader height={12} width={12} />
                                </div>
                            ) : (
                                cleanerTasks.length
                            )}
                        </div>

                        <div className="ml-auto mr-3 h-4 w-4">
                            <SvgComponent name="chevron-down" />
                        </div>
                    </button>
                </div>

                <List isOpen={isOpenTasks} setIsOpen={setIsOpenTasks} items={cleanerTasks} itemsTitle={t('HOSPITALITY_REQUESTS')} loading={tasksFetching} />
            </Droppable>

            <CleanerSelectionSection
                setCleanerHandler={setCleanerHandler}
                isOpen={isOpenCleanerSelection === item.id}
                setClose={() => setIsOpenCleanerSelection(null)}
                cleanerId={item.cleanerId}
            />
        </div>
    );
};
