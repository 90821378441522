import { useSelector } from 'react-redux';

import { name as nameState } from '../../../redux/slices/loginSlice';
import { SvgComponent } from '../svgComponent/SvgComponent';

export const UserInfo = () => {
    const name = useSelector(nameState);

    return (
        <div className="flex items-center gap-1 text-gray-20">
            <SvgComponent name="user" />
            <div className="text-sm font-medium">{name}</div>
        </div>
    );
};
