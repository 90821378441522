import { goToElement } from '../../../../api/functions';

export const LogsButton = ({ title, flag }) => {
    return (
        <button
            onClick={() => goToElement(flag)}
            type="button"
            className="box-border flex h-10 min-w-44 items-center justify-center rounded-sm bg-blue px-4 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-500"
        >
            {title}
        </button>
    );
};
