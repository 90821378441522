import { DndContext, DragOverlay } from '@dnd-kit/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../api/functions';
import { cleanersState, moveCleaners } from '../../../../redux/slices/hospitalitySlice';
import { Card } from '../../card/Card';
import { CleanersSection } from './cleanersSection/CleanersSection';
import { SupervisorsSection } from './supervisorsSection/SupervisorsSection';

export const CleanersToSupervisorsSection = () => {
    const dispatch = useDispatch();

    const cleaners = useSelector(cleanersState);

    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        const interval = setInterval(
            () => {
                if (cleaners.findIndex(cleaner => cleaner.isTouched) > -1) {
                    toast.error('Ваше распределение не применено!', toastOptions);
                }
            },
            3 * 60 * 1000
        );

        return () => clearInterval(interval);
    }, [cleaners]);

    const handleDragStart = event => {
        setActiveItem(cleaners.find(cleaner => cleaner.id === event.active.id));
    };

    const handleDragEnd = async event => {
        setActiveItem(null);

        if (event.over) {
            const supervisorId = event.over.id === 'default' ? '' : event.over.id;
            const cleaner = cleaners.find(cleaner => cleaner.id === event.active.id && cleaner.supervisorId === supervisorId);

            if (!cleaner) {
                const result = await dispatch(
                    moveCleaners({
                        data: {
                            id: event.active.id,
                            supervisorId,
                        },
                    })
                );

                if (result.error) {
                    toast.error(result.message, toastOptions);
                }
            }
        }
    };

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className="flex h-full w-full flex-row overflow-hidden border-l border-l-gray-10">
                <CleanersSection />
                <SupervisorsSection />
            </div>

            <DragOverlay dropAnimation={null}>{activeItem ? <Card>{activeItem.name}</Card> : null}</DragOverlay>
        </DndContext>
    );
};
