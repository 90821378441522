import { t } from 'i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ContentContainer } from '../components/common/ContentContainer';
import { Loader } from '../components/common/Loader';
import { SubHeader } from '../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../components/common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../components/common/subheader/SubHeaderRight';
import { Title } from '../components/common/subheader/Title';
import { Filter } from '../components/qualification/Filter';
import { ListQualificationButton } from '../components/qualification/ListQualificationButton';
import { Table } from '../components/qualification/Table';
import { book } from '../navigation/book';
import {
    filter_fetch as filterFetchState,
    qualificationError as qualificationErrorState,
    qualificationsListFetch,
    userQualificationRole as userQualificationRoleState,
} from '../redux/slices/qualificationSlice';

export const QualificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const filterFetch = useSelector(filterFetchState);
    const qualificationError = useSelector(qualificationErrorState);
    const userQualificationRole = useSelector(userQualificationRoleState);
    const qualificationOptions = filterFetch.qualifications;

    useEffect(() => {
        if (userQualificationRole === undefined || userQualificationRole === 'employer') {
            navigate(book.home.link);
        }
    });

    useEffect(() => {
        if (qualificationOptions.length === 0) {
            (async () => {
                await dispatch(qualificationsListFetch());
            })();
        }
    }, [dispatch, qualificationOptions]);

    if (qualificationError === 38) {
        return (
            <div className="flex h-20 w-full items-center justify-center">
                Нет ни одной созданной квалификации. Добавьте квалификацию в разделе "Список квалификаций"
            </div>
        );
    }

    if (qualificationOptions.length === 0) {
        return (
            <div className="flex h-20 w-full items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.qualification.key)}</Title>
                </SubHeaderLeft>

                <SubHeaderRight>
                    {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && <ListQualificationButton />}
                </SubHeaderRight>
            </SubHeader>

            <ContentContainer>
                <Filter />
            </ContentContainer>

            <div className="mx-auto w-full max-w-[calc(100%-2rem)] overflow-x-auto overflow-y-auto sm:max-w-[calc(100%-3rem)]" id="qualificationWrapper">
                <Table />
            </div>
        </>
    );
};
