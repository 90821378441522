import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { listenForOutsideClick } from '../../../api/functions';
import { setLanguageFetch } from '../../../redux/slices/loginSlice';
import { SvgComponent } from '../svgComponent/SvgComponent';

export const ChangeLanguage = () => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies();
    const dispatch = useDispatch();

    const menuLangRef = useRef(null);

    const [langMenu, setLangMenu] = useState(false);
    const [listening, setListening] = useState(false);

    useEffect(listenForOutsideClick(listening, setListening, menuLangRef, setLangMenu));

    const changeLanguage = lang => {
        setLangMenu(false);
        i18n.changeLanguage(lang).then(() => {
            dispatch(setLanguageFetch({ lang }));
            setCookie('i18next', lang, { path: '/' });
        });
    };

    return (
        <div className="relative ml-8 flex items-center gap-2 text-gray-20" ref={menuLangRef}>
            <button type="button" className="flex items-center justify-start gap-2" onClick={() => setLangMenu(!langMenu)}>
                <div className="shrink-0">
                    <SvgComponent name="globe" />
                </div>

                <div className="w-5 text-base font-medium uppercase">{cookies.i18next}</div>

                <div className="h-4 w-4 shrink-0">
                    <SvgComponent name="chevron-down" />
                </div>
            </button>

            {langMenu && (
                <ul className="absolute right-0 top-10 w-32 space-y-4 bg-white px-3 py-2 text-sm text-gray-50 shadow-3xl">
                    <li className={`relative cursor-pointer hover:text-blue ${cookies.i18next === 'ru' && 'text-blue'}`}>
                        <button className="flex w-full items-center justify-between" type="button" onClick={() => changeLanguage('ru')}>
                            {t('LANGUAGE_RU')}
                            {cookies.i18next === 'ru' && <SvgComponent name="check" />}
                        </button>
                    </li>

                    <li className={`relative cursor-pointer hover:text-blue ${cookies.i18next === 'en' && 'text-blue'}`}>
                        <button className="flex w-full items-center justify-between" type="button" onClick={() => changeLanguage('en')}>
                            {t('LANGUAGE_EN')}
                            {cookies.i18next === 'en' && <SvgComponent name="check" />}
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};
