import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { SvgComponent } from '../../common/svgComponent/SvgComponent';

export const FileSelector = ({ maxFiles = 5, disabled = false, accept = {}, callback = () => null, placeholder = '', initialFiles = [], maxSize = 20 }) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (initialFiles.length) {
            setFiles(initialFiles);
        }
    }, [initialFiles]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            if (files.length + acceptedFiles.length > maxFiles) {
                toast.error(t('IMAGE_SELECTOR_MAX_FILES_ERROR', { count: maxFiles }), toastOptions);
                return;
            }

            setFiles(prevState => [
                ...prevState,
                ...acceptedFiles.map((file, index) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        key: `${file.name}${index}`,
                    })
                ),
            ]);
        },
        onDropRejected: fileRejections => {
            fileRejections.forEach(file => {
                file.errors.forEach(error => {
                    let errorMessage = error.message;
                    if (error.code === 'file-too-large') {
                        errorMessage = t('IMAGE_SELECTOR_FILE_TOO_LARGE_ERROR', { count: maxSize });
                    }

                    toast.error(
                        <div>
                            {file.file.name}
                            <br />
                            {errorMessage}
                        </div>,
                        toastOptions
                    );
                });
            });
        },

        disabled,
        maxSize: maxSize * 1048576,
        accept,
        multiple: maxFiles !== 1,
    });

    useEffect(() => {
        callback(files);
    }, [files]);

    const removeFile = key => setFiles(files.filter(file => file.key !== key));

    return (
        <div className="flex flex-row flex-wrap gap-2">
            {files.map(file => (
                <div
                    className="relative flex h-28 w-28 flex-col items-center justify-center gap-2 overflow-hidden rounded-2 border-2 border-neutral-200 p-1"
                    key={file.key}
                >
                    {file.type.indexOf('image/') >= 0 && !file.type.includes('heic') && !file.type.includes('heif') ? (
                        <img src={file.preview} className="h-full w-full rounded-1 object-cover" alt={file.name} />
                    ) : (
                        <>
                            <div className="h-4 w-4">
                                <SvgComponent name="document" />
                            </div>

                            <div className="line-clamp-2 w-full text-center text-xs">{file.name}</div>
                        </>
                    )}

                    {!disabled && (
                        <div className="absolute flex h-full w-full items-center justify-center bg-black bg-opacity-30 opacity-0 transition-opacity duration-300 hover:opacity-100">
                            <button
                                type="button"
                                onClick={() => removeFile(file.key)}
                                className="flex h-10 w-10 items-center justify-center text-white hover:text-red-300"
                                disabled={disabled}
                            >
                                <div className="h-4 w-4">
                                    <SvgComponent name="basket" />
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {(files.length === 0 || maxFiles > 1) && (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    <div className="flex h-28 w-28 cursor-pointer flex-col items-center justify-center gap-1 rounded-2 border-2 border-dashed border-neutral-200 bg-zinc-50 p-1 text-gray transition-colors duration-300 hover:bg-neutral-100">
                        <div className="h-4 w-4">
                            <SvgComponent name="plus" />
                        </div>

                        <div className="select-none text-center text-xs">{placeholder || t('ADM_PLANNER_ADD_PHOTO_FILE')}</div>
                    </div>
                </div>
            )}
        </div>
    );
};
