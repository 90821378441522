import { root } from '../config';

const timeInMs = Date.now();

export const qualificationAPI = Object.freeze({
    // get users qualifications
    getUsersQualifications: {
        fetch: (username, auth_key_calendar, filter) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in filter) {
                if (item === 'page') {
                    formData.append('page', filter[item]);
                }
                if (item === 'filter_user_name') {
                    formData.append('filter_user_name', filter[item]);
                }

                if (item === 'venues') {
                    formData.append('filter_venue[]', filter[item].value);
                }

                if (item === 'departments') {
                    filter[item].map(el => formData.append('filter_department[]', el.value));
                }

                if (item === 'status') {
                    filter[item].map(el => formData.append('filter_left[]', el.value));
                }

                if (item === 'qualifications' && filter.status.length !== 0) {
                    filter[item].map(el => formData.append('filter_qualification[]', el.value));
                }
            }
            return fetch(
                `${root}?r=api/qualification/get-qualifications&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // get qualifications list
    getQualificationsList: {
        fetch: () => {
            return fetch(
                `${root}?r=api/qualification/get-passport-qualification-dict-list&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                }
            );
        },
    },

    // get qualifications period
    getQualificationsPeriodList: {
        fetch: () => {
            return fetch(
                `${root}?r=api/qualification/get-qualification-period-list&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                }
            );
        },
    },

    // add qualifications type
    addQualificationType: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (item === 'files') {
                    data[item].forEach((file, index) => formData.append(`file${index}`, file));
                } else {
                    formData.append(item, data[item]);
                }
            }

            return fetch(
                `${root}?r=api/qualification/add-passport-qualification&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // update qualifications type
    updateQualificationType: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (item === 'files') {
                    data[item].forEach((file, index) => formData.append(`file${index}`, file));
                } else {
                    formData.append(item, data[item]);
                }
            }

            return fetch(
                `${root}?r=api/qualification/update-passport-qualification&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // delete qualifications type
    deleteQualificationType: {
        fetch: (username, auth_key_calendar, id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('id', id);

            return fetch(
                `${root}?r=api/qualification/delete-passport-qualification&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // get user venues
    getUsersVenues: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(
                `${root}?r=api/qualification/get-user-venues&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // add user qualification
    addUserQualification: {
        fetch: (username, auth_key_calendar, dataQualification) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in dataQualification) {
                formData.append(item, dataQualification[item]);
            }

            return fetch(
                `${root}?r=api/qualification/add-qualification&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // update user qualification
    updateUserQualification: {
        fetch: (username, auth_key_calendar, dataQualification) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in dataQualification) {
                formData.append(item, dataQualification[item]);
            }

            return fetch(
                `${root}?r=api/qualification/update-qualification&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // delete user qualification
    deleteUserQualification: {
        fetch: (username, auth_key_calendar, qualification_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('qualification_id', qualification_id);

            return fetch(
                `${root}?r=api/qualification/delete-qualification&app_id=512&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },
});
