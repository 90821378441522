import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { toastOptions } from '../../../../api/functions';
import { useCheckRole } from '../../../../hooks/useCheckRole';
import {
    bmsChartPointsState,
    bms_detail_nodes_fetching as bms_detail_nodes_fetching_state,
    bms_doc_list as bms_doc_list_state,
    documentState as documentState_state,
    getBMSDocList,
    getBMSDocNodes,
    getBMSDocNodesTimeout,
    set_document,
} from '../../../../redux/slices/bms/bmsSliceDocuments';
import { bms_venues_list as bms_venues_list_state } from '../../../../redux/slices/bms/bmsSliceVenues';
import { Loader } from '../../../common/Loader';
import { SystemName } from '../../systems/SystemName';
import { VenueName } from '../../systems/VenueName';
import { ControlsSection } from './ControlsSection';
import { EditButton } from './EditButton';
import { ElementsSection } from './ElementsSection';
import { LogsButton } from './LogsButton';
import { TrendsTable } from './Tables/TrendsTable';
import { TrendsChart } from './chart/TrendsChart';

export const Detail = () => {
    const dispatch = useDispatch();
    const { documentId, venueId, systemId } = useParams();

    const bms_doc_list = useSelector(bms_doc_list_state);
    const bms_detail_nodes_fetching = useSelector(bms_detail_nodes_fetching_state);
    const documentState = useSelector(documentState_state);
    const bms_venues_list = useSelector(bms_venues_list_state);
    const bmsChartPoints = useSelector(bmsChartPointsState);

    const [bmsDetail, setBmsDetail] = useState({});

    const containerSize = useRef({
        width: 1920,
        height: 1080,
    });

    const isDocumentNodesView = useCheckRole('bms', 'bms.document_nodes.view');
    const isDocumentControlsView = useCheckRole('bms', 'bms.control_panel.view');
    const isDocumentEdit = useCheckRole('bms', 'bms.document.edit');
    const isTrendsView = useCheckRole('bms', 'bms.trends.view');

    useEffect(() => {
        (async () => {
            if (bms_doc_list.length === 0) {
                await dispatch(getBMSDocList({ object_id: venueId, direction_id: systemId }));
            }
        })();

        const venueIsKaizen = bms_venues_list.find(venue => Number(venue.id) === Number(venueId));
        if (venueIsKaizen && !venueIsKaizen.isKaizen) {
            sessionStorage.setItem('noKaizen', true);
        }
        return () => sessionStorage.removeItem('noKaizen');
    }, [bms_venues_list]);

    useEffect(() => {
        const bms = bms_doc_list.find(el => el.id === +documentId);
        if (bms) {
            setBmsDetail(bms);
            dispatch(set_document(bms));
        }
    }, [bms_doc_list]);

    useEffect(() => {
        if (sessionStorage.getItem('noKaizen') && documentState.nodes) {
            const crash = documentState.nodes.find(node => node.state === 'CRASH' || node.state === 'ALARM');
            if (crash) {
                toast.error(`Авария на оборудовании "${crash.name}". Объект системы не привязан в Kaizen. Заявка в Kaizen создана не будет.`, toastOptions);
                sessionStorage.removeItem('noKaizen');
            }
        }
        ReactTooltip.rebuild();
    }, [documentState.nodes]);

    useEffect(() => {
        if (bmsDetail && bmsDetail.hasOwnProperty('id')) {
            dispatch(getBMSDocNodes({ data: { doc_id: bmsDetail.id } }));
            const interval = setInterval(async () => {
                dispatch(getBMSDocNodesTimeout({ data: { doc_id: bmsDetail.id } }));
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [bmsDetail]);

    if (bmsDetail && bmsDetail.hasOwnProperty('id')) {
        return (
            <>
                <div>
                    <div className="mb-4 text-xl font-semibold uppercase text-black">
                        {bmsDetail.title}
                        {isDocumentEdit && <EditButton id={bmsDetail.id} />}
                    </div>

                    {bmsDetail.description && <div className="text-md mb-4 text-gray">{bmsDetail.description}</div>}

                    <VenueName />

                    <SystemName />

                    <div className="my-8 flex flex-row gap-4">{isTrendsView && <LogsButton title="Тренды" flag="trends" />}</div>

                    <div className="mb-8">
                        {bms_detail_nodes_fetching ? (
                            <div className="flex w-full justify-center">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {documentState.nodes?.length || documentState.controls?.length.length ? (
                                    <div className="w-full overflow-x-auto overflow-y-hidden">
                                        {isDocumentControlsView && <ControlsSection containerSize={containerSize} docId={bmsDetail.id} />}
                                        {isDocumentNodesView && <ElementsSection containerSize={containerSize} />}
                                    </div>
                                ) : (
                                    <p>Нет оборудования</p>
                                )}
                            </>
                        )}
                    </div>

                    {isTrendsView && <TrendsChart name={bmsDetail.title} bmsChartPoints={bmsChartPoints} docId={documentId} />}

                    {Object.keys(bmsChartPoints).length ? <TrendsTable data={bmsChartPoints} /> : ''}
                </div>

                <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="max-w-prose !rounded-md !px-2.5 !py-2" />
            </>
        );
    }
};
