import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../api/functions';
import { useCheckRole } from '../../../../hooks/useCheckRole';
import { documentState as documentState_state, setBMSControlValues, setFeedbackButtonState } from '../../../../redux/slices/bms/bmsSliceDocuments';
import { ButtonControl } from './controls/ButtonControl';
import { FeedbackButtonControl } from './controls/FeedbackButtonControl';
import { ListControl } from './controls/ListControl';
import { PresetValueControl } from './controls/PresetValueControl';
import { ToggleControl } from './controls/ToggleControl';
import { TriggerControl } from './controls/TriggerControl';

export const ControlsSection = ({ containerSize, docId }) => {
    const dispatch = useDispatch();

    const documentState = useSelector(documentState_state);

    const [controlsSelectsValues, setControlsSelectsValues] = useState([]);
    const [presetValueArr, setPresetValueArr] = useState(null);

    const isControlPanelControl = useCheckRole('bms', 'bms.control_panel.control');

    useEffect(() => {
        if (documentState.controls) {
            const valuesArr = documentState.controls.map(control => {
                const correctValue = control.data.values.find(value => value.value === control.value);

                return { cn_id: control.cn_id, values: correctValue ? correctValue : control.data.values[0] };
            });
            setControlsSelectsValues(valuesArr);

            const presetValues = documentState.controls.filter(control => control.lib_data.subtype === 'input');
            setPresetValueArr(presetValues.map(value => ({ cn_id: value.cn_id, value: value.value })));
        }
    }, [documentState.controls]);

    const handlerBtnClick = async control => {
        const data = {
            flag: 'btn',
            cn_id: control.cn_id,
            value: control.data.values[0].value,
            doc_id: docId,
        };
        const status = await dispatch(setBMSControlValues({ data }));
        if (status.result) {
            toast.success('Изменения сохранены', toastOptions);
        }
    };

    const handlerSetControlValue = async (control, val) => {
        const data = {
            flag: 'list',
            cn_id: control.cn_id,
            value: JSON.stringify(val),
            doc_id: docId,
        };
        setControlsSelectsValues(() => controlsSelectsValues.map(el => (el.cn_id === control.cn_id ? { ...el, values: val } : el)));

        const status = await dispatch(setBMSControlValues({ data }));
        if (status.result) {
            toast.success('Изменения сохранены', toastOptions);
        }
    };

    const handlerPresetValueCompleat = async ({ control, value }) => {
        if (value !== control.value) {
            const min = control.data.values[0].min;
            const max = control.data.values[0].max;

            if (value.length === 0) {
                setPresetValueArr(prev => prev.map(el => (el.cn_id === control.cn_id ? { ...el, value: control.value } : el)));

                return;
            }
            if (Number(value) < Number(min) || Number(value) > Number(max)) {
                toast.error('Значение не попадает в диапазон min-max', toastOptions);
                setPresetValueArr(prev => prev.map(el => (el.cn_id === control.cn_id ? { ...el, value: control.value } : el)));

                return;
            }

            const data = {
                cn_id: control.cn_id,
                value,
                doc_id: docId,
            };
            const status = await dispatch(setBMSControlValues({ data }));
            if (status.result) {
                toast.success('Изменения сохранены', toastOptions);
            }
        }
    };

    const handlerToggleChange = async (control, value) => {
        let checkboxValue;

        if (value) {
            checkboxValue = control.data.values[0].value_off;
        } else {
            checkboxValue = control.data.values[0].value_on;
        }

        const data = {
            cn_id: control.cn_id,
            value: checkboxValue,
            doc_id: docId,
        };
        const status = await dispatch(setBMSControlValues({ data }));
        if (status.result) {
            toast.success('Изменения сохранены', toastOptions);
        }
    };

    const handlerTriggerClick = async control => {
        const data = {
            cn_id: control.cn_id,
            value: control.data.values[0].value_start,
            doc_id: docId,
        };

        const status = await dispatch(setBMSControlValues({ data }));
        if (status.result) {
            toast.success('Изменения сохранены', toastOptions);
        }
    };

    const handlerFeedbackButtonClick = async control => {
        const data = {
            cn_id: control.cn_id,
            value: '',
            doc_id: docId,
            controlState: 'process',
        };
        const status = await dispatch(setBMSControlValues({ data }));
        if (status.result) {
            dispatch(setFeedbackButtonState(data));
        } else {
            toast.error(status.error, toastOptions);
        }
    };

    return (
        <div
            className="mb-1 flex flex-wrap place-content-start gap-4 border bg-red-200 p-2.5"
            style={{
                maxWidth: containerSize.current.width,
                maxHeight: containerSize.current.height / 2,
                minWidth: '720px',
                minHeight: '100px',
                width: '100%',
            }}
        >
            {documentState.controls?.length
                ? documentState.controls.map(control => {
                      const { subtype } = control.lib_data;
                      return (
                          <div key={control.cn_id} className="flex items-center gap-2 border border-gray-30 p-1">
                              <span className="text-xs">{control.name}</span>
                              {subtype === 'list' && (
                                  <ListControl
                                      controlsSelectsValues={controlsSelectsValues}
                                      control={control}
                                      handlerSetControlValue={handlerSetControlValue}
                                      isControlPanelControl={isControlPanelControl}
                                  />
                              )}
                              {subtype === 'button' && (
                                  <ButtonControl handlerBtnClick={handlerBtnClick} control={control} isControlPanelControl={isControlPanelControl} />
                              )}
                              {subtype === 'input' && (
                                  <PresetValueControl
                                      presetValueArr={presetValueArr}
                                      setPresetValueArr={setPresetValueArr}
                                      handlerPresetValueCompleat={handlerPresetValueCompleat}
                                      control={control}
                                  />
                              )}
                              {subtype === 'toggle' && <ToggleControl control={control} handlerToggleChange={handlerToggleChange} />}
                              {subtype === 'trigger' && <TriggerControl control={control} handlerTriggerClick={handlerTriggerClick} />}
                              {subtype === 'button_with_feedback' && <FeedbackButtonControl control={control} callback={handlerFeedbackButtonClick} />}
                          </div>
                      );
                  })
                : ''}
        </div>
    );
};
