import { t } from 'i18next';
import React from 'react';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { App } from '../App.tsx';
import { MainLayout } from '../components/common/MainLayout';
import { BreadcrumbsElement } from '../components/common/breadcrumbs/BreadcrumbsElement';
import { QualificationList } from '../components/qualification/QualificationList';
import { AdminSchedulerPage } from '../pages/AdminSchedulerPage';
import { ApplicationPage } from '../pages/ApplicationPage';
import { ApplicationsPage } from '../pages/ApplicationsPage';
import { CalendarPage } from '../pages/CalendarPage';
import { HospitalityPage } from '../pages/HospitalityPage';
import { NewsAndPollsPage } from '../pages/NewsAndPollsPage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { QualificationPage } from '../pages/QualificationPage';
import { UIPage } from '../pages/UIPage.tsx';
import { BMSCreateDocPage } from '../pages/bms/BMSCreateDocPage';
import { BMSDetailDocPage } from '../pages/bms/BMSDetailDocPage';
import { BMSDocumentsPage } from '../pages/bms/BMSDocumentsPage';
import { BMSEditDocPage } from '../pages/bms/BMSEditDocPage';
import { BMSEventLogPage } from '../pages/bms/BMSEventLogPage';
import { BMSPage } from '../pages/bms/BMSPage';
import { BMSServersPage } from '../pages/bms/BMSServersPage';
import { BMSSystemPage } from '../pages/bms/BMSSystemPage';
import { BMSVenuesPage } from '../pages/bms/BMSVenuesPage';
import { book } from './book';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<App />}>
            <Route
                element={<MainLayout />}
                handle={{
                    crumb: link => <BreadcrumbsElement link={link && book.home.link}>{t(book.home.key)}</BreadcrumbsElement>,
                }}
            >
                <Route path={book.home.link} element={<Navigate to="/calendar?link=apartments&type=days" />} />

                {/* Календарь */}
                <Route
                    path={book.calendar.link}
                    element={<CalendarPage />}
                    handle={{
                        crumb: () => <BreadcrumbsElement>{t(book.calendar.key)}</BreadcrumbsElement>,
                    }}
                />

                {/* Административный планировщик */}
                <Route
                    path={book.admin_scheduler.link}
                    element={<AdminSchedulerPage />}
                    handle={{
                        crumb: () => <BreadcrumbsElement>{t(book.admin_scheduler.key)}</BreadcrumbsElement>,
                    }}
                />

                {/* Квалификации */}
                <Route
                    handle={{
                        crumb: link => <BreadcrumbsElement link={link && book.qualification.link}>{t(book.qualification.key)}</BreadcrumbsElement>,
                    }}
                >
                    <Route path={book.qualification.link} element={<QualificationPage />} />
                    <Route
                        path={book.qualification_list.link}
                        element={<QualificationList />}
                        handle={{
                            crumb: () => <BreadcrumbsElement>{t(book.qualification_list.key)}</BreadcrumbsElement>,
                        }}
                    />
                </Route>

                {/* BMS */}
                <Route
                    path={book.bms.link}
                    element={<BMSPage />}
                    handle={{
                        crumb: link => <BreadcrumbsElement link={link && book.bms.link}>{t('BMS_SIDEBAR')}</BreadcrumbsElement>,
                    }}
                >
                    {/* redirect to venue */}
                    <Route path={book.bms.link} element={<Navigate to="/bms/venues" />} />
                    <Route
                        handle={{
                            crumb: link => <BreadcrumbsElement link={link && book.bms_venue.link}>{t(book.bms_venue.key)}</BreadcrumbsElement>,
                        }}
                    >
                        {/* venue */}
                        <Route path={book.bms_venue.link} element={<BMSVenuesPage />} />
                        <Route
                            handle={{
                                crumb: link => <BreadcrumbsElement link={link && book.bms_system.link}>{t(book.bms_system.key)}</BreadcrumbsElement>,
                            }}
                        >
                            {/* system */}
                            <Route path={`${book.bms_system.link}`} element={<BMSSystemPage />} />
                            <Route
                                handle={{
                                    crumb: link => <BreadcrumbsElement link={link && book.bms_document.link}>{t(book.bms_document.key)}</BreadcrumbsElement>,
                                }}
                            >
                                {/* document */}
                                <Route path={`${book.bms_document.link}`} element={<BMSDocumentsPage />} />
                                <Route
                                    path={`${book.bms_create_doc.link}`}
                                    element={<BMSCreateDocPage />}
                                    handle={{
                                        crumb: () => <BreadcrumbsElement>{t(book.bms_create_doc.key)}</BreadcrumbsElement>,
                                    }}
                                />
                                <Route
                                    path={`${book.bms_detail_doc.link}`}
                                    element={<BMSDetailDocPage />}
                                    handle={{
                                        crumb: () => <BreadcrumbsElement>{t(book.bms_detail_doc.key)}</BreadcrumbsElement>,
                                    }}
                                />
                                <Route
                                    path={`${book.bms_edit_doc.link}`}
                                    element={<BMSEditDocPage />}
                                    handle={{
                                        crumb: () => <BreadcrumbsElement>{t(book.bms_detail_doc.key)}</BreadcrumbsElement>,
                                    }}
                                />
                            </Route>
                        </Route>
                    </Route>
                    {/* server */}
                    <Route
                        path={book.bms_server.link}
                        element={<BMSServersPage />}
                        handle={{
                            crumb: () => <BreadcrumbsElement>{t(book.bms_server.key)}</BreadcrumbsElement>,
                        }}
                    />
                    {/* event log */}
                    <Route
                        path={book.bms_event_log.link}
                        element={<BMSEventLogPage />}
                        handle={{
                            crumb: () => <BreadcrumbsElement>{t(book.bms_event_log.key)}</BreadcrumbsElement>,
                        }}
                    />
                </Route>

                {/* Горничные */}
                <Route
                    path={book.hospitality.link}
                    element={<HospitalityPage />}
                    handle={{
                        crumb: () => <BreadcrumbsElement>{t(book.hospitality.key)}</BreadcrumbsElement>,
                    }}
                />

                {/* Новости и опросы */}
                <Route
                    path={book.newsAndPolls.link}
                    element={<NewsAndPollsPage />}
                    handle={{
                        crumb: () => <BreadcrumbsElement>{t(book.newsAndPolls.key)}</BreadcrumbsElement>,
                    }}
                />

                {/* UI */}
                <Route
                    path={book.ui.link}
                    element={<UIPage />}
                    handle={{
                        crumb: () => <BreadcrumbsElement>User Interface</BreadcrumbsElement>,
                    }}
                />

                {/* Заявки */}
                <Route path={book.applications.link} element={<ApplicationsPage />} />
                <Route path={`${book.applications.link}/:id`} element={<ApplicationPage />} />

                <Route
                    path="*"
                    element={<NotFoundPage />}
                    handle={{
                        crumb: () => <BreadcrumbsElement>404 page</BreadcrumbsElement>,
                    }}
                />
            </Route>
        </Route>
    )
);
