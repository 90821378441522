import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { addUniqueElements } from '../../../../../api/functions';
import { objectsState, positionsState, usersFetchingState, usersState } from '../../../../../redux/slices/newsAndPollsSlice';
import { Loader } from '../../../../common/Loader';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Checkbox } from '../../../../ui/checkbox/Checkbox';

export const UsersSection = ({ selectedUsers, setSelectedObjects, setSelectedPositions, setSelectedUsers, experience, selectedDepartments, isDisabled }) => {
    const { t } = useTranslation();

    const objects = useSelector(objectsState);
    const positions = useSelector(positionsState);
    const users = useSelector(usersState);
    const loading = useSelector(usersFetchingState);

    const [searchLine, setSearchLine] = useState('');
    const [onlySelected, setOnlySelected] = useState(false);

    const usersMemo = useMemo(() => {
        return users.filter(item => item.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0);
    }, [users, searchLine]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [usersMemo]);

    const selectAllItemsHandler = e => {
        if (e.target.checked) {
            setSelectedObjects(objects);
            setSelectedPositions(positions);
            setSelectedUsers(usersMemo);
        } else {
            setSelectedObjects([]);
            setSelectedPositions([]);
            setSelectedUsers([]);
        }
    };

    const selectItemHandler = e => {
        const user = usersMemo.find(el => el.id === e.target.value);

        if (e.target.checked) {
            setSelectedUsers(prevState => [...prevState, user]);

            setSelectedObjects(prevState => [
                ...addUniqueElements(
                    prevState,
                    objects.filter(object => object.users.includes(user.id)),
                    'id'
                ),
            ]);

            setSelectedPositions(prevState => [
                ...addUniqueElements(
                    prevState,
                    positions.filter(position => position.users.includes(user.id)),
                    'id'
                ),
            ]);
        } else {
            setSelectedUsers(prevState => prevState.filter(el => el.id !== user.id));

            setSelectedObjects(prevState =>
                prevState.filter(object =>
                    [
                        ...new Set(
                            selectedUsers
                                .filter(el => el.id !== user.id)
                                .flatMap(el => el.objects)
                                .map(el => el.id)
                        ),
                    ].includes(object.id)
                )
            );

            setSelectedPositions(prevState =>
                prevState.filter(position =>
                    [
                        ...new Set(
                            selectedUsers
                                .filter(el => el.id !== user.id)
                                .flatMap(el => el.objects)
                                .map(el => el.position)
                        ),
                    ].includes(position.id)
                )
            );
        }
    };

    return (
        <div className="w-[calc(100%/3-10px)]">
            <div className="mb-3 text-xs font-medium text-neutral-900">{t('NEWS_AND_POLLS_SELECT_EMPLOYEE')}</div>

            <div className="mb-3 text-xs">
                <Checkbox
                    title={`${t('NEWS_AND_POLLS_SHOW_ONLY_SELECTED')} (${selectedUsers.length})`}
                    onClick={e => {
                        setOnlySelected(e.target.checked);
                    }}
                    checked={onlySelected}
                />
            </div>

            <div className="h-[420px] flex-1 overflow-y-auto overflow-x-hidden rounded border border-gray-200">
                <div className="sticky top-0 bg-white p-1">
                    <div className="relative h-10 w-full">
                        <div className="absolute left-2.5 top-3 h-4 w-4 text-gray">
                            <SvgComponent name="search" />
                        </div>

                        <input
                            type="text"
                            placeholder={t('NEWS_AND_POLLS_SEARCH_BY_EMPLOYEES')}
                            className="box-border h-full w-full rounded border-gray-200 pl-8 text-sm"
                            value={searchLine}
                            onChange={e => setSearchLine(e.target.value)}
                            disabled={isDisabled}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-2 px-3 py-2 text-sm">
                    {loading ? (
                        <div className="flex justify-center">
                            <Loader width={24} />
                        </div>
                    ) : (
                        !!usersMemo.length && (
                            <>
                                {!searchLine.length && !onlySelected && (
                                    <div className="flex items-center py-px">
                                        <Checkbox
                                            name="user"
                                            value="all"
                                            title={t('NEWS_AND_POLLS_ALL_EMPLOYEES')}
                                            onClick={selectAllItemsHandler}
                                            checked={selectedUsers.length === usersMemo.length && usersMemo.length > 0}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                )}

                                {usersMemo.map(item => {
                                    const isChecked = selectedUsers.findIndex(el => el.id === item.id) > -1;
                                    let isItemDisabled = false;
                                    const period = experience.period ? +experience.period : 1;

                                    if (typeof experience.from === 'number' && experience.from * period > +item.experience) {
                                        isItemDisabled = true;
                                    }

                                    if (typeof experience.to === 'number' && experience.to * period < +item.experience) {
                                        isItemDisabled = true;
                                    }

                                    if (typeof experience.to === 'number' && typeof experience.from === 'number' && experience.to < experience.from) {
                                        isItemDisabled = false;
                                    }

                                    if (!item.departments.some(department => selectedDepartments.some(selected => selected.value === department))) {
                                        isItemDisabled = true;
                                    }

                                    return (
                                        <div
                                            key={item.id}
                                            className={`inline-flex items-center py-px ${onlySelected && !isChecked && 'hidden'}`}
                                            data-tip={item.name}
                                        >
                                            <Checkbox
                                                name="user"
                                                disabled={(isChecked && isItemDisabled) || isDisabled}
                                                value={item.id}
                                                title={item.name}
                                                checked={isChecked}
                                                onClick={selectItemHandler}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};
