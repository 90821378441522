import { useEffect, useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getVenueStatusColorByServerStatus } from '../../../api/functions/bms/getVenueStatusByServerStatus';
import { useCheckRole } from '../../../hooks/useCheckRole';
import {
    bms_doc_list_fetching as bms_doc_list_fetching_state,
    bms_doc_list as bms_doc_list_state,
    getBMSDocList,
    removeBMSDoc,
    selectedDocumentForCopyState,
    setSelectedDocumentForCopy,
} from '../../../redux/slices/bms/bmsSliceDocuments';
import { bmsAlarmsState, bms_servers_state } from '../../../redux/slices/bms/bmsSliceServers';
import { Loader } from '../../common/Loader';
import { PopupActionConfirmation } from '../mainPage/PopupActionConfirmation';
import { CopiedDocument } from './copiedDocument/CopiedDocument';

export const DocumentList = () => {
    const dispatch = useDispatch();
    const { venueId, systemId } = useParams();

    const bms_doc_list_fetching = useSelector(bms_doc_list_fetching_state);
    const bms_doc_list = useSelector(bms_doc_list_state);
    const bmsServers = useSelector(bms_servers_state);
    const selectedDocumentForCopy = useSelector(selectedDocumentForCopyState);
    const bmsAlarms = useSelector(bmsAlarmsState);

    const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const isDocumentDelete = useCheckRole('bms', 'bms.document.del');

    useEffect(() => {
        if (!bms_doc_list.length) {
            dispatch(getBMSDocList({ object_id: venueId, direction_id: systemId }));
        }
    }, [venueId]);

    const handlerSystemRemoveClick = id => {
        dispatch(removeBMSDoc({ id }));

        setIsOpenConfirmationPopup(false);
    };

    const handlerCopyDocument = documentId => {
        dispatch(setSelectedDocumentForCopy(documentId));
    };

    const getColor = id => {
        // Если есть ошибки на точке
        if (bmsAlarms.devProblems?.bad_status?.document_id && bmsAlarms.devProblems.bad_status.document_id.includes(id)) {
            return 'bms-error';
        }

        // Непросмотренная ошибка на объекте
        if (bmsAlarms.blink?.document_id && bmsAlarms.blink.document_id.includes(id)) {
            return 'animate-pulse bms-error';
        }

        // Ошибка на объекте
        if (bmsAlarms.alarms?.document_id && bmsAlarms.alarms.document_id.includes(id)) {
            return 'bms-error';
        }

        // Цвет в зависимости от статуса сервера
        return getVenueStatusColorByServerStatus(+venueId, bmsServers);
    };

    if (bms_doc_list_fetching) {
        return (
            <div className="flex h-28 w-52 items-center justify-center">
                <Loader />
            </div>
        );
    }
    return (
        <>
            {bms_doc_list.length ? (
                bms_doc_list.map(doc => (
                    <div className="relative" key={`bms${doc.id}`}>
                        <Link
                            to={`/bms/venues/${venueId}/system/${systemId}/documents/${doc.id}`}
                            className={`flex h-28 w-52 flex-col items-center justify-center overflow-hidden rounded-md border-2 border-gray-10 p-2 text-white transition-colors hover:border-blue ${getColor(
                                doc.id
                            )}`}
                        >
                            {doc.title && <div className="line-clamp-2 text-center text-sm font-bold uppercase leading-4">{doc.title}</div>}

                            {doc.description && <div className="mt-0.5 line-clamp-2 text-center text-xs leading-3">{doc.description}</div>}
                        </Link>

                        <div className="absolute right-1 top-1 flex flex-row items-center">
                            <button
                                className="flex h-6 w-6 cursor-pointer items-center justify-center text-white transition-colors hover:text-gray-800"
                                type="button"
                                onClick={() => handlerCopyDocument(doc.id)}
                            >
                                <FaRegCopy size={16} />
                            </button>

                            {isDocumentDelete && (
                                <button
                                    type="button"
                                    className="flex h-6 w-6 cursor-pointer items-center justify-center text-white transition-colors hover:text-gray-800"
                                    onClick={() => {
                                        setIsOpenConfirmationPopup(true);
                                        setSelectedDocument(doc);
                                    }}
                                >
                                    <MdDeleteOutline size={20} />
                                </button>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex h-28 w-64 flex-col items-center justify-center overflow-hidden p-2">Нет созданных систем</div>
            )}

            {selectedDocumentForCopy && <CopiedDocument />}

            {isOpenConfirmationPopup && (
                <PopupActionConfirmation
                    isOpen={isOpenConfirmationPopup}
                    setIsOpen={setIsOpenConfirmationPopup}
                    callback={handlerSystemRemoveClick}
                    element={selectedDocument}
                    title="Вы действительно хотите удалить систему"
                />
            )}
        </>
    );
};
