import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../../../api/functions';
import { BitPopup } from '../../../nodePopup/BitPopup';
import { ButtonData } from './buttonData/ButtonData';
import { ControlDataPoint } from './controlDataPoint/ControlDataPoint';

const initialState = {
    writePoint: null,
    originValue: '',
    passedValue: '',
    delay: '',
    bit: false,
    isUseDataPointControl: false,
    dataPointControl: {
        dataPointControlObj: '',
        operand: '',
        dataPointControlValue: '',
        dataPointControlValueMax: '',
        bit: false,
        range: false,
    },
};

export const FeedbackButtonControl = ({ nodeOptions, setValues, setWritePoint, setCheckPopupFields, control, nodeControlOptions }) => {
    const [data, setData] = useState(initialState);
    const [bitPopupIsOpen, setBitPopupIsOpen] = useState(false);
    const [bitData, setBitData] = useState(null);

    useEffect(() => {
        // поиск пустых полей
        const valuesArr = Object.values(data);
        const emptyValues = valuesArr.length && valuesArr.filter(el => el?.length === 0);
        // если нет контрольной точки
        if (!data.isUseDataPointControl) {
            emptyValues.length > 0 ? setCheckPopupFields(false) : setCheckPopupFields(true);
        } else {
            // если есть контрольная точка, проверяем обязательные поля если не выбран диапазон
            if (!data.dataPointControl.range) {
                emptyValues.length > 0 || !data.dataPointControl.dataPointControlObj || !data.dataPointControl.dataPointControlValue
                    ? setCheckPopupFields(false)
                    : setCheckPopupFields(true);
            } else {
                // console.log('123');
                // если с диапазоном проверяем и максимальное значение
                emptyValues.length > 0 ||
                !data.dataPointControl.dataPointControlObj ||
                !data.dataPointControl.dataPointControlValue ||
                !data.dataPointControl.dataPointControlValueMax
                    ? setCheckPopupFields(false)
                    : setCheckPopupFields(true);
                // проверка на минимум должен быть меньше максимума
                if (
                    data.dataPointControl.dataPointControlValue &&
                    data.dataPointControl.dataPointControlValueMax &&
                    Number(data.dataPointControl.dataPointControlValue) >= Number(data.dataPointControl.dataPointControlValueMax)
                ) {
                    toast.error('Минимум не может быть больше или равен максимуму', toastOptions);
                    setCheckPopupFields(false);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        try {
            JSON.parse(control.data);
        } catch (e) {
            const values = control.data.values[0];

            setData({ ...values });

            setValues([...control.data.values]);
            setWritePoint(values.writePoint.value);
        }
    }, [control, nodeOptions]);

    const handlerBitValueChange = (_, value, field) => {
        if (field === 'dataPointControlValue') {
            setData({ ...data, dataPointControl: { ...data.dataPointControl, dataPointControlValue: value } });
            setValues([{ ...data, dataPointControl: { ...data.dataPointControl, dataPointControlValue: value } }]);
        } else {
            setData({ ...data, [field]: value });
            setValues([{ ...data, [field]: value }]);
        }
    };
    return (
        <div className="flex flex-col">
            <ButtonData
                initialState={initialState}
                data={data}
                setData={setData}
                nodeOptions={nodeOptions}
                setValues={setValues}
                setWritePoint={setWritePoint}
                setBitPopupIsOpen={setBitPopupIsOpen}
                setBitData={setBitData}
            />

            {data.isUseDataPointControl && (
                <ControlDataPoint
                    initialState={initialState}
                    nodeControlOptions={nodeControlOptions}
                    data={data}
                    setData={setData}
                    setValues={setValues}
                    setBitPopupIsOpen={setBitPopupIsOpen}
                    setBitData={setBitData}
                />
            )}
            {bitPopupIsOpen && <BitPopup nodeStatusInfo={bitData} setBitPopupIsOpen={setBitPopupIsOpen} callback={handlerBitValueChange} />}
        </div>
    );
};
