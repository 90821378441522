import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useEffect, useMemo, useState } from 'react';

import { goToElement } from '../../../../../api/functions';

export const DocumentChart = ({ dataChart, title }) => {
    const [chartOptions, setChartOptions] = useState(null);

    useEffect(() => {
        if (dataChart.hasOwnProperty('values')) {
            setChartOptions(
                dataChart.values.map(el => ({ name: el.label.replace(/^Root\/Objects\/Data\//, ''), tooltip: { valueDecimals: 2 }, data: el.data }))
            );
        } else {
            setChartOptions([]);
        }
    }, [dataChart]);

    const options = useMemo(
        () => ({
            chart: {
                backgroundColor: 'white',
                height: '550px',
                zoomType: 'x',
            },
            credits: { enabled: false },
            title: {
                text: `<h1 id="chart-title">${title}</h1>`,
                align: 'left',
            },
            xAxis: {
                lineColor: '#a4a8ab',
                lineWidth: 1,
                tickColor: '#a4a8ab',
                tickLength: 8,
                labels: {
                    align: 'center',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Lato, sans-serif',
                    },
                },
                type: 'datetime',
                currentDateIndicator: true,
                dateTimeLabelFormats: {
                    day: '%d %b',
                    week: '%d %b',
                    month: '%b %Y',
                    year: '%Y',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                lineColor: '#a4a8ab',
                lineWidth: 1,
                tickColor: '#a4a8ab',
                tickWidth: 1,
                tickLength: 8,
                labels: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Lato, sans-serif',
                    },
                },
                type: 'linear',
                tickAmount: 10,
            },

            tooltip: {
                borderColor: '#2c3e50',
                shared: true,
                formatter: function (tooltip) {
                    let selectedPoints = this.points;

                    const sortSelectedPoints = [...selectedPoints].sort((a, b) => b.y - a.y);
                    const tmp11 = { ...this, points: sortSelectedPoints };

                    return tooltip.defaultFormatter.call(tmp11, tooltip);
                },
            },
            series: chartOptions,
            accessibility: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: e => {
                                goToElement('chartTable');

                                const scrollToElement = document.getElementById(e.point.category);
                                const tableHeadHigh = document.getElementById('chartTableHead').offsetHeight;
                                scrollToElement.classList.add('bg-red-400');

                                document.getElementById('chartTable').scrollTo({
                                    top: scrollToElement.offsetTop - tableHeadHigh,
                                    behavior: 'smooth',
                                });

                                setTimeout(() => {
                                    scrollToElement.classList.remove('bg-red-400');
                                }, 1500);
                            },
                        },
                    },
                },
            },
        }),
        [title, chartOptions]
    );
    if ((dataChart.values && !dataChart.values.length) || (dataChart.data && !dataChart.data.length)) {
        return <div className="flex h-96 w-full items-center justify-center">Данных за выбранный период нет</div>;
    }
    return (
        <div id="container">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
